import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'PanelCAD' },
  ];

  return (
    <Layout>
      <SEO title="System Components: PanelCAD" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components</h2>
        <h1 className='title title--xxxl color-primary'>PanelCAD</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>An extremely important ingredient in the success or failure of a project is the
          timeliness and accuracy of the shop drawings. Infinity Structures developed our own
          custom in-house wall panel detailing program named PanelCAD. This BIM based software
          is highly automated, faster and more accurate, simplifies modifying panel details after
          approval comments, improves checking and double checking, reduces the opportunity for
          human error, and automates the material ordering process. Since it is our own in-house
          software, we are continuously improving PanelCAD for even better efficiency.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/panelcad/gallery/01-34218.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
    }
`
